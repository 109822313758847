async function apiCall({ endPoint, method, payload }) {
    let token = localStorage.getItem("token");
    let headers = {
        "Content-Type": "application/json",
    };
    if (token) {
        headers.Authorization = "Bearer " + token
    }
    return fetch(`${process.env.REACT_APP_URL}/api/${endPoint}`, {
        method: method,
        headers: headers,
        body: JSON.stringify(payload),
    })
        .then(data => {
            console.log('Request sent successfully:', data);
            return data.json();
        })
        .then(data => {
            if (data?.token) {
                localStorage.setItem("token", data.token)
            }
            if (data?.success) {
                return { success: true, data: data };
            }
            else {
                return { success: false }
            }
        })
        .catch(error => {
            console.error('Error while sending request:', error);
            return { success: false, error: error };
        });
}
export default apiCall;