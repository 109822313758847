import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import Home from './pages/Home';
import "./App.css"
function App() {
  const [login, setLogin] = useState(false);
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setLogin(true)
    }
  }, [])
  return (
    <Home login={login} setLogin={setLogin} />
  );
}

export default App;
