import React, { useState } from 'react'
import Login from '../components/Login';
import UserTable from '../components/UserTable';

function Home({ login, setLogin }) {
    return (
        <>
            <div style={{ textAlign: 'center' }}>Home</div>
            <div>
                {
                    !login
                        ?
                        <Login setLogin={setLogin} />
                        :
                        <UserTable />
                }
            </div>
        </>

    )
}

export default Home