import React, { useState } from 'react'
import apiCall from '../utils/apiCall';
function Login({ setLogin }) {

    const [formData, setFormData] = useState({ emailOrPhoneNumber: '', password: '' })
    const [error, setError] = useState('');

    const submit = async (e) => {
        e.preventDefault();
        if (formData.emailOrPhoneNumber && formData.password) {
            //api call
            const res = await apiCall(
                {
                    method: 'POST',
                    endPoint: 'admin/login',
                    payload: {
                        emailOrPhoneNumber: formData.emailOrPhoneNumber,
                        password: formData.password
                    }
                }
            )
            console.log(res)
            if (res.success) {
                setLogin(true)
            }
            else {
                setError("Some Error Occured")
            }
            console.log(formData)
        }
        else {
            setError("Please Submit All Required Details")
        }
    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((old) => {
            return {
                ...old,
                [name]: value
            }

        })
    }
    return (
        <>
            <div>Login</div>
            <form onSubmit={submit}>
                <div>
                    <input
                        type="text"
                        placeholder='emailOrPhoneNumber'
                        value={formData.emailOrPhoneNumber}
                        name='emailOrPhoneNumber'
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <input
                        type="password"
                        placeholder='password'
                        value={formData.password}
                        name='password'
                        onChange={handleChange}
                    />
                </div>
                <div>
                    <button type='submit'>Submit</button>
                </div>
            </form>
        </>
    )
}

export default Login