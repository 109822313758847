import React, { useState } from 'react'
import "../styles/userForm.css";
import apiCall from '../utils/apiCall';
import UploadImage from './UploadImage';

function UserForm({
    locationData,
    setOpenModal,
    modalData: {
        _id,
        name,
        userName,
        email,
        phoneNumber,
        age,
        gender,
        location,
        telegramLink,
        datingCharge,
        imageWithHalfFace,
        imageWithFullFace,
        subscriptionDetail,
        isVerified
    } }) {

    // console.log(locationData)
    const genderOptions = ['male', 'female']
    const verifiedOptions = [true, false]
    const [msg, setMsg] = useState('');
    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState({
        name: name,
        userName: userName,
        email: email,
        phoneNumber: phoneNumber,
        age: age,
        gender: gender,
        datingCharge: datingCharge,
        locationId: location?._id,
        telegramLink: telegramLink,
        isVerified: isVerified ? "true" : "false",
        userImgHalfName: '',
        userImgHalf: '',
        userImgFullName: '',
        userImgFull: ''
    })
    console.log("form data in userform => ", formData)
    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log("handle change = > ", name, value)
        setFormData((oldData) => {
            return {
                ...oldData,
                [name]: value
            }
        })
    }
    const updateUser = async (e) => {
        e.preventDefault();
        console.log(formData)
        console.log("isverified payload is => ", formData?.isVerified)
        const payload = {
            ...formData,
            phoneNumber: Number(formData?.phoneNumber),
            datingCharge: Number(formData?.datingCharge),
            age: Number(formData?.age),
            isVerified: formData?.isVerified === "true" ? true : false
        }
        console.log("sending payload is => ", payload)
        setLoading(true)
        const res = await apiCall({
            method: "PUT",
            endPoint: `admin/updateSingleUserProfile/${_id}`,
            payload: payload
        })
        setLoading(false)
        console.log(res)
        if (res.success) {
            setMsg("User Updated Successfully")
        }
        else {
            setMsg("Some Error Occured")
        }
        setTimeout(() => {
            setMsg("")
        }, 3000)
    }
    return (
        <div className='userFormContainer'>
            <div className='userFormInnerContainer'>
                <div
                    style={{ textAlign: "center " }}
                >Update Form</div>
                <form onSubmit={updateUser}>
                    <div className='fieldContainer'>
                        <input
                            className='field'
                            type='text'
                            name='name'
                            placeholder='name'
                            value={formData.name}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='fieldContainer'>
                        <input
                            className='field'
                            type='text'
                            name='userName'
                            placeholder='userName'
                            value={formData.userName}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='fieldContainer'>
                        <input
                            className='field'
                            type='email'
                            name='email'
                            placeholder='Email'
                            value={formData.email}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='fieldContainer'>
                        <input
                            className='field'
                            type='number'
                            name='phoneNumber'
                            placeholder='Phone Number'
                            value={formData.phoneNumber}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='fieldContainer'>
                        <input
                            className='field'
                            type='number'
                            name='age'
                            placeholder='Age'
                            value={formData.age}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='fieldContainer'>
                        <input
                            className='field'
                            type='number'
                            name='datingCharge'
                            placeholder='Dating Charge'
                            value={formData.datingCharge}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='fieldContainer'>
                        <input
                            className='field'
                            type='text'
                            name='telegramLink'
                            placeholder='Telegram Link'
                            value={formData.telegramLink}
                            onChange={handleChange}
                        />
                    </div>
                    <div className='fieldContainer'>
                        <select
                            className='field'
                            name='gender'
                            value={formData.gender}
                            onChange={handleChange}
                        >
                            <option value="">Please select</option>
                            {genderOptions.map((option, index) => (
                                <option key={index} value={option}>{option}</option>
                            ))}
                        </select>
                    </div>
                    <div className='fieldContainer'>
                        <select
                            className='field'
                            name='locationId'
                            value={formData.locationId}
                            onChange={handleChange}
                        >
                            <option value="">Please select</option>
                            {locationData?.map((option, index) => (
                                <option key={index} value={option?._id}>{option?.cityName}</option>
                            ))}
                        </select>
                    </div>
                    <div className='fieldContainer'>
                        <select
                            className='field'
                            name='isVerified'
                            value={formData.isVerified}
                            onChange={handleChange}
                        >
                            <option value="">Please select</option>
                            {["true", "false"].map((option, index) => (
                                <option key={index} value={option}>{option}</option>
                            ))}
                        </select>
                    </div>
                    <UploadImage
                        imageWithHalfFace={imageWithHalfFace}
                        imageWithFullFace={imageWithFullFace}

                        userImgHalfName={formData.userImgHalfName}
                        userImgHalf={formData.userImgHalf}
                        userImgFullName={formData.userImgFullName}
                        userImgFull={formData.userImgFull}

                        setFormData={setFormData}
                        setMsg={setMsg}
                    />
                    <button
                        className='formBtn'
                        type='submit'
                        disabled={loading ? true : false}
                    >
                        {loading ? "...Loading" : "Submit"}
                    </button>
                    <button
                        onClick={() => setOpenModal((old) => {
                            return !old
                        })}
                        className='formBtn'
                    >
                        close
                    </button>
                </form>
                {msg && <div
                    style={{ color: msg?.includes("Error") ? "red" : msg?.includes("Success" || "success") ? "green" : "red" }}
                >{msg}</div>}
            </div>
        </div>
    )
}

export default UserForm