import React, { useState } from 'react'
import { GenerateBase64 } from '../utils/pictureUpload'
import imageCompression from 'browser-image-compression';

function SingleImageUpload({
    imgSrc,
    imageName,
    imageData,
    setFormData,
    setMsg
}) {

    console.log(imageName, imageData)
    const [photo, setPhoto] = useState();

    const [uploadingImage, setUploadingImage] = useState(false)

    const options = {
        maxSizeMB: .1,
        useWebWorker: true,
    }

    const handleDrop = async (acceptedFiles) => {
        let file = acceptedFiles[0];
        console.log("pre =>" + file.size)
        if (file) {
            setUploadingImage(true)
            file = await imageCompression(file, options);
            setUploadingImage(false)
        }
        console.log("after =>" + file.size)
        if (file?.size > 2 * 1024 * 1024) {
            setMsg("Please upload less than 3mb image")
            setTimeout(() => {
                setMsg("")
            }, 3000)
            setPhoto("")
            return;
        }
        if (file) {
            const objectURL = URL.createObjectURL(file); // Access it through the window object
            // const objectURL = window.URL.createObjectURL(file); // Access it through the window object
            const newFile = Object.assign(file, {
                preview: objectURL,
            });
            setPhoto(file);
        }
    };
    console.log(photo)

    const submitBtn = async () => {
        if (photo) {
            setMsg("uploading...")
            const resultPhoto = await GenerateBase64(photo)
            console.log(resultPhoto)

            setFormData((old) => {
                return {
                    ...old,
                    [imageName]: photo?.name,
                    [imageData]: resultPhoto
                }
            })
            setMsg("Successfully uploaded")
            setTimeout(() => {
                setMsg("")
            }, 3000)
        }

    }
    return (
        <div>
            upload photo:
            {
                uploadingImage ?
                    "Loading.."
                    :

                    <input
                        onChange={(e) => handleDrop(e.target.files)}
                        type='file'
                        accept=".jpg, .jpeg, .png"
                    />
            }
            {photo ? (
                <div>
                    Selected file: {photo.name} ({Math.round(photo.size / 1024)} KB)
                    <img style={{ width: "60px", height: "60px" }} src={photo?.preview} alt="Preview" />
                </div>
            )
                :
                <img style={{ width: "60px", height: "60px" }} src={imgSrc} alt="Preview" />

            }

            <button
                style={{ cursor: "pointer" }}
                type='button'
                onClick={submitBtn}>upload</button>
        </div>
    )
}

export default SingleImageUpload