import React, { useEffect, useState } from 'react'
import apiCall from '../utils/apiCall';
import "../styles/userTable.css"
import UserData from './UserData';
import UserForm from './UserForm';

function UserTable() {
    const [data, setData] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false)
    const [locationData, setLocationData] = useState([])
    const [openModal, setOpenModal] = useState(false);
    const [modalData, setModalData] = useState({});

    const [query, setQuery] = useState({
        locationId: '',
        searchOnNEP: ''
    });

    useEffect(() => {
        btn();
    }, [openModal])

    useEffect(() => {
        setLocation()
    }, [])
    const setLocation = async () => {
        const res = await apiCall({
            method: "GET",
            endPoint: `location/getAllLocations`
        });
        if (res.success) {
            setLocationData(res?.data?.data)
            if (res?.data?.data?.length === 1) {
                setQuery((oldData) => {
                    return {
                        ...oldData,
                        locationId: res.data.data[0]?._id
                    }
                })
            }
        }
    }
    const btn = async () => {
        setLoading(true)
        const res = await apiCall({
            method: "GET",
            endPoint: `admin/getAllUsers?locationId=${query.locationId}&searchOnNEP=${query.searchOnNEP}`
        });
        if (res.success) {
            setData(res.data.users)
        }
        else {
            console.log(res.error)
            setError('Some Error Occured')
            setTimeout(() => {
                setError('')
            }, 2000)
        }
        setLoading(false)
    }
    const handleChange = (e) => {
        const { name, value } = e.target
        setQuery((old) => {
            return {
                ...old,
                [name]: value
            }
        })
    }
    const handleChangeLocation = (e) => {
        const { name, value } = e.target;
        setQuery((old) => {
            return {
                ...old,
                [name]: value,
            };
        });
    };

    return (
        <>
            {
                openModal && <UserForm modalData={modalData} locationData={locationData} setOpenModal={setOpenModal} />
            }
            <div className='userTableContainer'>
                <div style={{ color: "white" }}>UserTable</div>

                {setError && <div>{error}</div>}
                <div className='filterContainer'>
                    <div>
                        <select
                            name="locationId"
                            value={query.locationId}
                            onChange={handleChangeLocation}
                            style={{ padding: "10px" }}
                        >
                            {
                                locationData?.map((e) => (
                                    <option id={e?._id} >{e?.cityName}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div>
                        <input
                            placeholder='name/email/username'
                            name='searchOnNEP'
                            type='text'
                            value={query?.searchOnNEP}
                            onChange={handleChange}
                            style={{ padding: "10px" }}
                        />
                    </div>
                    <button style={{ padding: "10px" }} onClick={btn}>filter</button>
                </div>
                <div className='tableContainer'>
                    <table>
                        <thead>
                            <tr>
                                <th>S.no.</th>
                                <th>Name</th>
                                <th>User Name</th>
                                <th>age</th>
                                <th>Gender</th>
                                <th>Location</th>
                                <th>Email</th>
                                <th>Phone Number</th>
                                <th>Dating Charge</th>
                                <th>Image With Half Face</th>
                                <th>Image With Full Face</th>
                                <th>Is Trial</th>
                                <th>Joined On</th>
                                <th>Is Verified</th>
                                <th>isSubscribed</th>
                                <th>action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                !loading
                                    ?
                                    <UserData
                                        users={data}
                                        openModal={openModal}
                                        setOpenModal={setOpenModal}
                                        modalData={modalData}
                                        setModalData={setModalData}
                                    />
                                    :
                                    "...Loading"
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default UserTable