import React from 'react'
import SingleImageUpload from './SingleImageUpload'
function UploadImage({
    imageWithHalfFace,
    imageWithFullFace,
    setMsg,
    setFormData
}) {
    return (
        <>
            <div className='fieldContainer'>
                <div>Image With Half Face</div>
                <SingleImageUpload
                    imgSrc={imageWithHalfFace}
                    imageName="userImgHalfName"
                    imageData="userImgHalf"
                    setFormData={setFormData}
                    setMsg={setMsg}
                />
            </div>
            <div className='fieldContainer'>
                <div>Image With full Face</div>
                <SingleImageUpload
                    imgSrc={imageWithFullFace}
                    imageName="userImgFullName"
                    imageData="userImgFull"
                    setFormData={setFormData}
                    setMsg={setMsg}
                />
            </div>
        </>
    )
}

export default UploadImage