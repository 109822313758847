import React from 'react'

function UserData({ users, openModal, setOpenModal, modalData, setModalData }) {

    const actionBtn = (e) => {
        setOpenModal(!openModal)
        setModalData(e)
    }
    // console.log(modalData)
    return (
        <>
            {
                users && users?.length > 0 && users?.map((e, i) => {
                    const {
                        _id,
                        name,
                        userName,
                        age,
                        gender,
                        location,
                        email,
                        phoneNumber,
                        datingCharge,
                        imageWithHalfFace,
                        imageWithFullFace,
                        isTrial,
                        createdAt,
                        subscriptionDetail,
                        isVerified
                    } = e;
                    return (
                        <tr key={_id}>
                            <td>{i}</td>
                            <td>{name}</td>
                            <td>{userName}</td>
                            <td>{age}</td>
                            <td>{gender}</td>
                            <td>{location?.cityName}</td>
                            <td>{email}</td>
                            <td>{phoneNumber}</td>
                            <td>{datingCharge ? datingCharge : 0}</td>
                            <td><img style={{ height: "60px", width: "60px" }} src={imageWithHalfFace} alt='half image' /></td>
                            <td><img style={{ height: "60px", width: "60px" }} src={imageWithFullFace} alt='full image' /></td>
                            <td>{isTrial ? isTrial : "done"}</td>
                            <td>{createdAt.split('T')[0]}</td>
                            <td>{isVerified === true ? "true" : "false"}</td>
                            <td>{subscriptionDetail?.isSubscribed === true ? "true" : "false"}</td>
                            <td
                                style={{ textDecoration: "underline", cursor: "pointer" }}
                                onClick={() => { actionBtn(e) }}
                            >Edit</td>
                        </tr>
                    )
                })
            }
        </>
    )
}

export default UserData